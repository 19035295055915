import { nodeListToArray } from './helpers/nodeListToArray';
import { pageWithDonationField } from './pages/pageWithDonationField/pageWithDonationField';
import { profilePage } from './pages/profilePage/profilePage';
import { Alert } from './modules/ace-alert';
import { blockClicksOnLoad } from './modules/block-clicks-on-load';
import { showHidePassword } from './modules/show-hide-password';
import { CartTimer } from './modules/cart-timer';
import { stickyCart } from './instances/stickyCart';
import { SvgSprite } from './modules/svg-loader';
import { Modal } from './modules/a11y-modal';
import { StateDropdown } from './modules/stateDropdown';
import { RevealPanel } from './modules/revealPanel';
import { initNavSubmenus } from './instances/navigation';
import { Accordion } from './modules/accordion';
import { specialProductionPage } from './pages/specialProductionPage/specialProductionPage';
import { myAccountPage } from './pages/my-account/my-account';
import { specialActivitiesPage } from './pages/specialActivitiesPage/specialActivitiesPage';


import AddressApi from './modules/address-api'
import { StateSummary } from './interfaces/address';

import debounce = require('lodash.debounce');

const init = () => {
    // SVG Sprite Inject
    new SvgSprite('/Static/img/adage-sprite.svg');
    new CartTimer();
    pageWithDonationField();
    profilePage();
    blockClicksOnLoad();
    showHidePassword();
    initModals();
    initAlerts();
    initStateDropdowns();
    initRevealPanels();
    initNavSubmenus();
    initBackToTopButton();
    initAccordions();
    specialProductionPage();
    myAccountPage();
    specialActivitiesPage();

    // Global event listeners
    window.addEventListener('resize', debounce(() => {
        browserSizeChangeHandler();
    }, 50));
}

const initModals = () => {
    const modals = Array.prototype.slice.call(document.querySelectorAll('[data-modal]')) as HTMLElement[];
    modals.forEach(modal => {
        new Modal(modal);
    });
}

const initAccordions = () => {
    // Accordion Init
    const accordions = nodeListToArray(document.querySelectorAll('[data-accordion-list]'));
    accordions.forEach(accordion => {
        const oneOpen = accordion.getAttribute('data-one-open-at-a-time');
        new Accordion({
            container: accordion,
            oneOpenAtATime: oneOpen === 'True'
        });
    });
}

const initAlerts = () => {
    const alerts = Array.prototype.slice.call(document.querySelectorAll('[data-alert]')) as HTMLElement[];
    alerts.forEach(alert => {
        const newAlert = new Alert(alert);
        // Refresh sticky cart position when page changes height. Otherwise the positioning gets out of alignment.
        newAlert.on('dismiss', () => {
            if (stickyCart) {
                stickyCart.update();
            }
        });
    });
}

const initStateDropdowns = () => {
    const stateDropdown = document.querySelector('#State') as HTMLSelectElement;
    const countryDropdown = document.querySelector('#Country') as HTMLSelectElement;
    if (stateDropdown && countryDropdown) {
        new StateDropdown(stateDropdown, countryDropdown);
    }
}


const countryDropDownList = document.querySelectorAll<HTMLSelectElement>(".js-country");

countryDropDownList.forEach(countryDropDown => countryDropDown.addEventListener("change", (e) => {
    const selectedCountryId = parseInt(countryDropDown.value);
    const addressType = countryDropDown.getAttribute("data-address-type");
    AddressApi.getStatesForCountry(selectedCountryId).then(results => {
        updateStatesDropDown(results, addressType);
    });
}));


function updateStatesDropDown(states: Array<StateSummary>, addressType: string) {
    const stateDropDown = document.querySelector<HTMLSelectElement>(".js-state[data-address-type=" + addressType + "]");
    stateDropDown.innerHTML = '';
    if (states.length) {
        states.forEach(state => {
            var option = document.createElement('option');
            option.value = state.stateCode.toString();
            option.innerHTML = state.description;
            stateDropDown.appendChild(option);
        });
        stateDropDown.disabled = false;
    }
    else {
        stateDropDown.disabled = true;
    }
}

const initRevealPanels = () => {
    const reveals = [].slice.call(document.querySelectorAll('[data-panel]')) as HTMLElement[];
    reveals.forEach(reveal => {
        new RevealPanel(reveal.id);
    });
}

const initBackToTopButton = () => {
    const topLink = document.querySelector('[data-top-link]') as HTMLAnchorElement;
    const topAnchor = document.querySelector('#top') as HTMLAnchorElement;
    if (topLink) {
        topLink.addEventListener('click', (e) => {
            e.preventDefault();
            topAnchor.focus();
            window.scroll(0, 0);
        });
    }
}

const browserSizeChangeHandler = () => {
    if (stickyCart) {
        stickyCart.update();
    }
}

init();